<template>
    <nav class="navbar">
        <div class="container">
            <!-- logo/brand -->
            <div class="navbar-brand">
                <router-link class="navbar-logo mt-3 m-2"
                             :to="{ name: 'Home' }">
                    <img src="../assets/eduworks-logo.svg"
                         alt="Eduworks logo"
                         class="logo-image"
                         @click="showMobileMenu = showMobileMenu" />
                </router-link>

                <a role="button"
                   class="navbar-burger mt-2"
                   :class="{ 'is-active': showMobileMenu }"
                   @click="(showMobileMenu = !showMobileMenu), fixBackground()"
                   aria-label="menu"
                   aria-expanded="false"
                   data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"
                          class="my-4"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div class="navbar-menu"
                 id="nav-links">
                <div class="navbar-end">

                    <router-link :to="{ name: 'CADRE' }"
                                 class="navbar-item">
                        Platform
                    </router-link>
                    <!-- <router-link :to="{ name: 'Platforms' }"
                                 class="navbar-item">
                        Platforms
                    </router-link> -->
                    <!-- <router-link :to="{ name: 'Solutions' }"
                                 class="navbar-item">
                        Solutions
                    </router-link> -->
                    <router-link :to="{ name: 'About' }"
                                 class="navbar-item">
                        About Us
                    </router-link>
                </div>
            </div>

            <!---- mobile --->
            <div class="navbar-menu hidden mobile-nav"
                 :class="{ 'is-active hidden': showMobileMenu }"
                 id="nav-links">
                <div class="container">
                    <div class="section py-2">
                        <div class="block ml-4 has-text-centered">
                            <router-link :to="{ name: 'Platforms' }"
                                         class="navbar-item">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">Platforms</div>
                            </router-link>
                            <router-link :to="{ name: 'Solutions' }"
                                         class="navbar-item">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">Solutions</div>
                            </router-link>
                            <router-link :to="{ name: 'About' }"
                                         class="navbar-item">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">About Us</div>
                            </router-link>

                            <!-- <router-link :to="{ name: 'About' }">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">About Eduworks</div>
                            </router-link>
                            <hr />
                            <router-link :to="{ name: 'Events' }">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">News &amp; Events</div>
                            </router-link>
                            <hr />
                            <router-link :to="{ name: 'Contact' }">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">Contact Us</div>
                            </router-link>
                            <hr />
                            <router-link :to="{ name: 'Careers' }">
                                <div class="title is-5 my-5"
                                     @click="showMobileMenu = !showMobileMenu, fixBackground()">Careers</div>
                            </router-link>
                            <hr />
                            <router-link :to="{ name: 'Government Solutions' }">
                                <h5 class="title is-5 my-5"
                                    @click="showMobileMenu = !showMobileMenu, fixBackground()">Government Solutions</h5>
                            </router-link>
                            <hr />
                            <router-link :to="{ name: 'Case Studies' }">
                                <h5 class="title is-5 my-5"
                                    @click="showMobileMenu = !showMobileMenu, fixBackground()">Case Studies</h5>
                            </router-link> -->
                            <!-- <hr />
                            <h5 class="title is-5">
                                Platforms
                                <i class="fas fa-chevron-down ml-4 has-text-primary pt-2"></i>
                            </h5>
                            <div class="columns is-multiline is-mobile is-centered">
                                <div class="column is-5"
                                     v-for="product in products"
                                     :key="product.id">
                                    <div class="px-4"
                                         style="width: fit-content; margin: 0 auto;">
                                        <a :href="getProductUrl(product.url)"
                                           :target="getProductTarget(product.url)">
                                            <img class="product-img"
                                                 :src="productLogoImage(product.logo)"
                                                 :alt="product.logo.alternativeText" />
                                        </a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navbar',
    data: () => {
        return {
            showMobileMenu: false,
        }
    },
    mounted() {
        if (this.$store.state.products.length < 1)
            this.$store.dispatch('getProducts')
    },
    computed: {
        products() {
            return this.$store.state.products
        }
    },
    methods: {
        fixBackground() {
            this.$emit('fix-background', this.showMobileMenu)
        },
        hideMobileMenu: function () {
            this.showMobileMenu = false
        },
        getProductUrl(url) {
            if (url) return url
            return '/contact'
        },
        getProductTarget(url) {
            if (url.charAt(0) === '/') return ''
            if (url) return '_blank'
            return ''
        },
        productLogoImage(logo) {
            var apiUrl = this.$store.state.strapiApiUrl

            if (logo) {
                if (logo.formats && logo.formats.thumbnail) {
                    return apiUrl + logo.formats.thumbnail.url
                }
                if (logo.formats && logo.formats.small) {
                    return apiUrl + logo.formats.small.url
                }
                if (logo.formats && logo.formats.medium) {
                    return apiUrl + logo.formats.medium.url
                }
                if (logo.formats && logo.formats.large) {
                    return apiUrl + logo.formats.large.url
                }
                return apiUrl + logo.url
            }
            return null
        }
    }
}
</script>
