<template>
    <footer class="footer"
            :class="{ 'fixed': mobileMenuVisible }">
        <div class="has-text-centered">
            <div class="container">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-2-desktop column is-one-half-mobile">
                        <div class="title-6">Platforms</div>
                        <template v-for="product in products">
                            <a :key="product.id"
                               :href="getProductUrl(product.url)"
                               :target="getProductTarget(product.url)"
                               :class="{ 'is-draft': product.published_at === null }">
                                {{ product.name }}
                                <i v-if="product.url.startsWith('http')"
                                   class="external-link-icon fas fa-external-link-alt is-hidden-mobile"></i>
                            </a>
                        </template>

                        <!-- <a href="https://www.cassproject.org/" target="_blank">CASS <i class="fas fa-external-link-alt"></i></a>
                        <a href="https://pebl.pro/" target="_blank">PeBL Pro <i class="fas fa-external-link-alt"></i></a>
                        <router-link :to="{ name: 'Contact' }">REVA</router-link>-->
                    </div>
                    <div class="column is-2-desktop column is-one-half-mobile">
                        <div class="title-6">About Us</div>
                        <router-link :to="{ name: 'About' }">About Eduworks</router-link>
                        <router-link :to="{ name: 'Events' }">Events</router-link>
                        <!-- <router-link :to="{ name: 'Case Studies' }">Case Studies</router-link> -->
                        <router-link :to="{ name: 'Careers' }">Careers</router-link>
                        <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
                    </div>
                    <div class="column is-3-desktop column">
                        <div class="title-6">Contact</div>
                        <div class="py-1">
                            <i class="far fa-envelope has-text-primary"></i>
                            <a class="is-inline"
                               href="mailto:info@eduworks.com"
                               target="_blank">&nbsp;&nbsp;info@eduworks.com</a>
                        </div>
                        <div class="py-1">
                            <i class="fas fa-phone has-text-primary"></i>&nbsp;
                            <a class="is-inline"
                               target="_blank"
                               href="tel:+15417530844">+1 (541) 753-0844</a>
                        </div>
                        <div class="py-1">
                            <i class="fas fa-map-marker-alt has-text-primary"></i>&nbsp;
                            <a href="https://goo.gl/maps/xzwM7knEgMMQFPZv9"
                               target="_blank"
                               class="is-inline">
                                400 SW 4th Street
                                <br />Suite 110
                                <br />Corvallis, OR 97333 USA
                            </a>
                        </div>
                    </div>
                    <div class="column is-1">
                        <div class="v1 hide-mobile"></div>
                    </div>
                    <div class="column is-4-desktop is-full-width-mobile has-text-centered">
                        <img class="block logo-image"
                             src="../assets/eduworks-logo.svg" />
                        <SocialMediaIcons />
                        <div class="block">
                            © 2001 - {{ currentYear }} Eduworks Corporation.
                            <br />All rights reserved.
                            <br />
                            <span class="has-text-weight-bold">
                                <router-link :to="{ name: 'Terms' }">Terms of Use &amp; Privacy Policy</router-link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import SocialMediaIcons from '../components/socialMediaIcons.vue'
export default {
    name: 'Footer',
    components: {
        SocialMediaIcons
    },
    props: ['mobileMenuVisible'],
    mounted() {
        if (this.$store.state.products.length < 1)
            this.$store.dispatch('getProducts')
    },
    computed: {
        products() {
            return this.$store.state.products
        },
        currentYear() {
            return new Date().getFullYear()
        }
    },
    methods: {


        hideMobileMenu: function () {
            this.showMobileMenu = false
        },
        getProductUrl(url) {
            if (url) return url
            return '/contact'
        },
        getProductTarget(url) {
            if (url) return '_blank'
            return ''
        }
    }
}
</script>