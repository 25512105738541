<template>
    <div id="home-page" class="home">
        <div id="heroAnimation"
             class="hero section animate vh mb-6">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-mobile">
                        <div class="column is-6-desktop is-8-tablet is-10-mobile">
                            <h1 style="line-height: 1.35em"
                                class="title is-1 has-text-balance">
                                Providing <span class="has-border-bottom-primary">AI solutions</span> for diverse business
                                needs
                            </h1>
                            <router-link :to="{ name: 'CADRE' }"
                                         class="button is-primary is-radiusless has-text-weight-bold is-block mt-4 is-inline-block primary-button">
                                Our Platform
                                <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                            </router-link>
                            <!-- <router-link :to="{ name: 'Platforms' }"
                                         class="button is-primary is-radiusless has-text-weight-bold is-block mt-4 is-inline-block primary-button">
                                Our Platforms
                                <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
            </div>

            <popup /> <!-- Popup content controlled by Strapi CMS -->

            <div class="columns is-centered">
                <div class="column is-1">
                    <a href="#section2"> <i
                           class=" fas fa-chevron-down fa-3x has-text-primary scroll-down-arrow hide-mobile "></i> </a>
                    <div id="section2"></div>
                </div>
            </div>
        </div>

        <section class="hero section"
                 v-scrollanimation>
            <div class="mt-6">
                <div class="container">
                    <h2 class="title is-1">Platforms </h2>
                    <div v-for="product in products"
                         :key="product.id"
                         @click="goToProduct(product.url)"
                         class="product">
                        <div class="columns">
                            <div class="column">
                                <h4 class="title is-4 mb-1"
                                    :class="{ 'is-draft': product.published_at === null }">
                                    {{ product.tagline }}
                                </h4>
                                <p class="product-description">
                                    {{ product.description }}
                                </p>
                                <a :href="getProductUrl(product.url)"
                                   :target="getProductTarget(product.url)">
                                    <span v-if="product.url"
                                          class="has-underline">Explore {{ product.name }}</span>
                                    <span v-else
                                          class="has-underline">Contact Us</span>
                                    <i class=" fas fa-arrow-right ml-2 primary-button-icon "></i>
                                </a>
                            </div>
                            <div class="column is-one-third">
                                <div class="product-logo-wrapper is-pulled-right">
                                    <img :src="productLogoImage(product.logo)"
                                         :alt="product.logo.alternativeText"
                                         class="product-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero section is-medium"
                 v-scrollanimation>
            <div class="px-4">
                <div class="container">
                    <h2 class="title is-1 mb-6">Events</h2>
                    <div class="featured-news">
                        <article v-for="(article, index) in allNewsArticles.slice(0, 3)"
                                 :key="index + '_' + article.title"
                                 :class="{ 'is-draft': article.published_at === null }">
                            <div class="columns">
                                <div class=" column is-narrow is-hidden-mobile ">
                                    <a v-if="article.externalURL"
                                       class="title is-5"
                                       :href="'//' + article.externalURL"
                                       target="_blank">
                                        <div class="news-image"
                                             :style="{ backgroundImage: `url(${articleBackgroundImage(article)})`, }">
                                        </div>
                                    </a>
                                    <router-link v-else
                                                 :to="{ path: '/event/' + article.url, }"
                                                 class="title is-5">
                                        <div class="news-image"
                                             :style="{ backgroundImage: `url(${articleBackgroundImage(article)})`, }">
                                        </div>
                                    </router-link>
                                </div>
                                <div class="column">
                                    <div class="featured-news-title">
                                        <template v-if="article.externalURL">
                                            <a class="title is-5"
                                               :href="'//' + article.externalURL"
                                               target="_blank"><span class="icon">
                                                    <i class="fas fa-arrow-right"></i></span>
                                                {{ article.title }} </a>
                                        </template>
                                        <template v-else>
                                            <router-link v-if="!article.externalURL"
                                                         :to="{ path: '/event/' + article.url, }"
                                                         class="title is-3"><span class="icon">
                                                    <i class=" fas fa-arrow-right "></i></span>
                                                {{ article.title }}
                                            </router-link>
                                        </template>
                                        <div v-for="articleTag in article.tags"
                                             class="tag is-light is-radiusless "
                                             :key="articleTag.id"> {{ articleTag.tag }} </div>

                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="mt-6 mb-2">
                        <router-link :to="{ name: 'Events' }">
                            <button class=" button is-primary is-radiusless has-text-weight-bold primary-button ">
                                View All
                                <i class=" fas fa-arrow-right ml-2 primary-button-icon "></i></button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <hr />
        <section clas="hero">
            <div class="hero-body has-text-centered"
                 v-scrollanimation>
                <p class="title is-4">Request a demo</p>
                <router-link :to="{ name: 'Contact' }"
                             class="button is-primary is-radiusless has-text-weight-bold primary-button">
                    Get started
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
        </section>

        <!-- <section class="hero section">
            <div class="container">
                <h1 class="title is-1 has-text-centered pb-5">Case Studies</h1>
                <div class="columns">
                    <div v-for="caseStudy in caseStudies.slice(0, 2)"
                         :key="caseStudy.id"
                         class="column is-half-tablet has-shadow p-0 m-2">
                        <router-link :to="{ path: 'case-studies/' + caseStudy.url }">
                            <div class="cs-feature-tile px-0">
                                <div class="cs-feature-image"
                                     :style="{ backgroundImage: `url(${`https://cms.eduworks.com` + caseStudy.image.url})`, }">
                                </div>
                                <h2 :class="{ 'is-draft': caseStudy.published_at === null }"
                                    class="cs-feature-title title"> {{ caseStudy.title }} </h2>
                                <div class=" tags is-flex is-flex-direction-column is-pulled-right mx-2 ">
                                    <div class=" tag is-primary has-text-weight-bold mb-1 is-radiusless mr-3 cs-feature-tag "
                                         v-for="caseStudyTag in caseStudy.tags"
                                         :key="caseStudyTag.id"> {{ caseStudyTag.tag }} <br /> </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="mt-6 has-text-centered mb-2">
                    <router-link :to="{ name: 'Case Studies' }">
                        <button class=" button is-primary is-radiusless has-text-weight-bold primary-button "> View All <i
                               class=" fas fa-arrow-right ml-2 primary-button-icon "></i> </button>
                    </router-link>
                </div>
            </div>
        </section> -->
    </div>
</template>

<script>
import Popup from "../components/popup.vue";

export default {
    name: "Home",
    components: {
        Popup,
    },
    data: () => {
        return {};
    },
    mounted() {
        if (this.$store.state.caseStudies.length < 1)
            this.$store.dispatch("getCaseStudies");
        if (this.$store.state.allNewsArticles.length < 1)
            this.$store.dispatch("getAllNewsArticles");
        if (this.$store.state.products.length < 1)
            this.$store.dispatch("getProducts");
    },
    computed: {
        caseStudies() {
            return this.$store.state.caseStudies;
        },
        allNewsArticles() {
            return this.$store.state.allNewsArticles;
        },
        products() {
            var products = this.$store.state.products;
            return products.filter((x) => x.showOnHomePage === true);
        },
    },
    methods: {
        // getImgUrl(url) {
        //     return require(this.strapiApiUrl + url)
        // },
        getProductUrl(url) {
            if (url) return url;
            return "/contact";
        },
        getProductTarget(url) {
            // console.log("url:", url);
            if (url.charAt(0) === "/") return "";
            if (url) return "_blank";
            return "";
        },
        goToProduct(url) {
            if (url.charAt(0) === "/") {
                this.$router.push(url);
            } else if (url) {
                window.open(url, "_blank").focus();
            } else {
                this.$router.push("/contact");
            }
        },
        productLogoImage(logo) {
            var apiUrl = this.$store.state.strapiApiUrl;

            if (logo) {
                // // if (logo.formats && logo.formats.thumbnail) {
                // //     return apiUrl + logo.formats.thumbnail.url
                // // }
                // if (logo.formats && logo.formats.small) {
                //     return apiUrl + logo.formats.small.url
                // }
                if (logo.formats && logo.formats.medium) {
                    return apiUrl + logo.formats.medium.url;
                }
                if (logo.formats && logo.formats.large) {
                    return apiUrl + logo.formats.large.url;
                }
                return apiUrl + logo.url;
            }
            return null;
        },
        articleBackgroundImage(article) {
            var apiUrl = this.$store.state.strapiApiUrl;

            // if (article.peblBlog == true) {
            //     apiUrl = this.$store.state.peblApiUrl;
            // }

            if (article.image) {
                if (article.image.formats && article.image.formats.thumbnail) {
                    return apiUrl + article.image.formats.thumbnail.url;
                }
                if (article.image.formats && article.image.formats.small) {
                    return apiUrl + article.image.formats.small.url;
                }
                if (article.image.formats && article.image.formats.medium) {
                    return apiUrl + article.image.formats.medium.url;
                }
                if (article.image.formats && article.image.formats.large) {
                    return apiUrl + article.image.formats.large.url;
                }
                return apiUrl + article.image.url;
            }
            return null;
        },
    },
};
</script>
