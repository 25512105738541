<template>
    <div role="main">
        <section class="section hero vh is-dark">
            <div class="has-text-centered">
                <img class="logo"
                     src="@/views/cadre/assets/CADRE-logo_dark.svg">
                <h1 class="title is-4 mb-6"
                    style="width: 75%; margin: 0 auto;">
                    Common Architecture for Distributed Readiness and Evaluation
                </h1>
                <p class="mb-4"><strong>
                        Empower your Personnel <i class="mx-2 has-text-warning fa-solid fa-caret-up"></i>
                        Track and Analyze Skills <i class="mx-2 has-text-warning fa-solid fa-caret-up"></i>
                        Maximize Readiness
                    </strong>
                </p>
                <router-link to="/contact"
                             class="
                                button
                                is-large is-primary is-radiusless
                                has-text-weight-bold
                                is-block
                                mt-4
                                is-inline-block
                                primary-button
                            ">
                    Request a Demo
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
        </section>

        <!-- <section class="section">
            <div class="container content">
                <div class="columns is-centered">
                    <div class="column is-9">
                                <h3 class="title s"></h3>
                        <p>
                            Eduworks develops sophisticated content management tools to propel training
                            development to new levels of efficiency.</p>

                        <p class="subtitle is-5 ml-0 my-4">Our goal is to streamline training development and
                            incorporate new and updated content with ease. </p>
                        <p>
                            We apply powerful machine learning techniques, developed by Eduworks for U.S.
                            Government clients, to make training content more manageable, scalable, and
                            maintainable.
                        </p>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="section is-medium">
            <div class="container content">
                <h2 class="title is-2 mb-5 has-text-centered">Your competency-driven training catalyst</h2>
                <div class="columns is-centered"
                     style="margin: 0 auto">
                    <div class="column is-8-desktop is-full-tablet">
                        <p><strong>CADRE</strong> integrates training development with competency
                            frameworks, ensuring that
                            every training program directly supports mission-relevant job requirements. Its modular
                            design supports multiple training systems and learning and content management platforms,
                            while its powerful analytics tools provide the insights you need to ensure warfighters are
                            always prepared.</p>
                        <hr class="my-6" />
                        <div class="title mb-5 mb-6">Key Features</div>
                        <div class="columns is-multiline is-variable is-6">
                            <div class="column is-half-desktop is-full-tablet">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <img class="feature-icon"
                                             src="./assets/icon-competencies.svg">
                                    </div>
                                    <div class="column">
                                        <div class="has-text-weight-bold my-2">Competency Frameworks</div>
                                        <p>Define, import, and customize competency frameworks that align with job
                                            requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-half-desktop is-full-tablet">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <img class="feature-icon"
                                             src="./assets/icon-modular.svg">
                                    </div>
                                    <div class="column">
                                        <div class="has-text-weight-bold my-2">Modular and Customizable</div>
                                        <p>Fully integrates with existing systems and adapts to the unique needs of any
                                            organization.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-half-desktop is-full-tablet">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <img class="feature-icon"
                                             src="./assets/icon-insights.svg">
                                    </div>
                                    <div class="column">
                                        <div class="has-text-weight-bold my-2">Data-Driven Insights</div>
                                        <p>Link competencies to real-time assessment data and gain actionable insights
                                            through advanced visualization and analytics.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="column is-half-desktop is-full-tablet">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <img class="feature-icon"
                                             src="./assets/icon-workforce.svg">
                                    </div>
                                    <div class="column">
                                        <div class="has-text-weight-bold my-2">Force Readiness</div>
                                        <p>Map current force availability and skills to critical competency needs,
                                            ensuring readiness for mission-critical tasks.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="column is-half-desktop is-full-tablet">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <img class="feature-icon"
                                             src="./assets/icon-secure.svg">
                                    </div>
                                    <div class="column">
                                        <div class="has-text-weight-bold my-2">Secure and Low Risk</div>
                                        <p>Authority to Operate on PlatformOne up to IL5 in the USN and IL2 in the USAF.
                                            ATO on CloudOne for IL4 in-progress.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section is-medium has-background-light">
            <div class="columns is-centered" v-scrollanimation>
                <div class="column is-7">
                    <div class="title mb-5">Training without insight is just repetition</div>
                    <p>Militaries strive to tie training programs to real-world job performance. But gaps remain
                        between skill development and competency measurement, leading to limited visibility into
                        force readiness and inefficient deployment of mission-critical skill sets. The lack of
                        alignment with competency frameworks leaves organizations with performance gaps and
                        inefficiencies.</p>
                    <div>
                        <a class="button is-primary mt-6"
                           href="./documents/Eduworks_CADRE.pdf"
                           target="_blank">
                            <span class="icon"><i class="fa-solid fa-file-arrow-down"></i></span>
                            <span>Download one-pager</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="section hero is-fullheight is-dark">

            <div class="hero-body">
                <div class="columns is-centered">
                    <div class="column is-7">
                        <h2 class="title is-2 mb-5 has-text-centered">Why CADRE?</h2>
                        <div class="columns is-centered is-multiline">
                            <div class="column">
                                <p><strong class="has-text-warning">Ensure Readiness</strong> </p>
                                <p>From development to deployment, CADRE ensures that your critical skillsets are
                                    identified, developed, and ready to be deployed when needed.</p>
                            </div>
                            <div class="column">
                                <p><strong class="has-text-warning">Bridge Performance Gaps</strong></p>
                                <p>Connect and map performance data to individual and team competencies, ensuring skill
                                    gaps
                                    are identified and addressed.</p>
                            </div>
                            <div class="column">
                                <p><strong class="has-text-warning">Digitize the Personnel Lifecycle</strong></p>
                                <p>Leverage existing competency data from manuals, job
                                    descriptions, and other documents to build a fully digitized training and workforce
                                    management process.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-7">
                        <div v-scrollanimation>
                            <div class="box has-background-black">
                                <h3 class="title is-3">
                                    Ready to transform your training into a powerful, competency-driven ecosystem?
                                </h3>
                                <p class="has-text-white mb-4">With <strong class="has-text-warning">CADRE</strong>, your organization can ensure that every
                                    warfighter and team is fully equipped
                                    to meet the demands of their missions — when it matters most.</p>
                                    <router-link to="/contact"
                                                 class="button is-warning has-text-weight-bold is-block is-inline-block primary-button ">
                                        Get Started <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                                    </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
export default {
    name: "CADRE",
    data: () => {
        return {
            name: "CADRE",
        };
    },
    metaInfo: {
        title: "CADRE",
        template: null,
    },
};
</script>

<style scoped lang="scss">
.hero.vh {
    display: grid;
    place-items: center;
    justify-content: unset;
}
.logo {
    width: clamp(400px, 50vw, 800px);
    max-width: 100%;
    margin: 2rem 0;
    animation: slideRight 2s;
    -webkit-animation: slideRight 2s;
    -moz-animation: slideRight 2s;
    -o-animation: slideRight 2s;
    -ms-animation: slideRight 2s;
}

.hero-body {
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.feature-icon {
    width: 5rem;
}

.column .box {
    height: 100%;
}
</style>
